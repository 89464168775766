import React from "react";
import CommonSectionHeader from "../../components/Shared/CommonSectionHeader";

const AboutUs = () => {
  return (
    <div id="aboutUs" className="aboutUs-section">
      <div className="common-container">
        <CommonSectionHeader text="About Us" />
        <p className="aboutUs__top--paragraph">
          Our Tradition Of Best Hospitality
        </p>
        <div className="aboutUs-body">
          <div className="aboutUs__logo">
            <img
              className="aboutUs__logo--img"
              src="https://res.cloudinary.com/djfzcmvmr/image/upload/v1721189490/sri-sri/replicate-prediction-03bcr208jnrgg0cgqvhbb9wwd4_qvnswg.png"
              alt=""
            />
          </div>
          <div className="aboutUs__text">
            <p className="aboutUs__text--paragraph">
              ‘Sahaj Events’ has always valued their client’s intimate and
              joyous
              <br />
              moments with utmost sincerity and dedication. Weddings are just
              <br />
              not only an occasion but its an event which leaves a mark in the
              <br />
              memories of the people. Sahaj Events has surely been successful
              <br /> in making dreams come true on the couple’s big day. Love,
              Colours of joy & Happiness are our main targets to craft for
              <br /> our clients. We not only makes an event possible but gives
              you an
              <br /> experience to remember with all smiles. With a team of over
              15 years of experience , here we are to make
              <br /> your day much bigger & brighter.
              <br />
              You’ve found us, we are meant to make it even bigger & better.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
