import React from "react";
import CommonButton from "../../components/Shared/CommonButton";
import { Link as ScrollLink } from "react-scroll";

const BackgroundOverlaySection = () => {
  return (
    <div className="backgroundOverlay-section">
      <div className="common-container">
        <div className="backgroundOverlay-wrapper">
          <h1 className="backgroundOverlay__heading">
            Khushiyan Sahaj Ke Saath
          </h1>
          <div className="backgroundOverlay__button">
            <ScrollLink
              to="contactUs"
              spy={true}
              smooth={true}
              offset={0}
              duration={500}
            >
              <CommonButton text="MAKE AN ENQUIRY" iconShow={true} />
            </ScrollLink>
          </div>
        </div>
      </div>
      <div className="backgroundOverlay-overlay"></div>
    </div>
  );
};

export default BackgroundOverlaySection;
