import React from "react";
import CommonSectionHeader from "../../components/Shared/CommonSectionHeader";

const OurServices = () => {
  return (
    <div id="services" className="ourServices-section">
      <div className="common-container">
        <CommonSectionHeader text="Our Services" />
        <div className="ourServices-body">
          <div
            className="ourServices__card"
            style={{
              backgroundImage: `url("https://res.cloudinary.com/djfzcmvmr/image/upload/v1721187692/sri-sri/service_decor_1_o1dori.webp")`,
            }}
          >
            <h4 className="ourServices__card--header">DECOR</h4>
            <p className="ourServices__card--title titleOne">
              – All wedding events
            </p>
            <p className="ourServices__card--title titleTwo">
              – Corporate events
            </p>
            <div className="ourServices__card--overlay"></div>
          </div>
          <div
            className="ourServices__card"
            style={{
              backgroundImage: `url("https://res.cloudinary.com/djfzcmvmr/image/upload/v1721187699/sri-sri/service_budget_2_umwh0w.jpg")`,
            }}
          >
            <h4 className="ourServices__card--header">BUDGET MANAGEMENT</h4>
            <p className="ourServices__card--title titleOne">
              – Providing flexible
            </p>
            <p className="ourServices__card--title titleTwo">
              – Services with your budget
            </p>
            <div className="ourServices__card--overlay"></div>
          </div>
          <div
            className="ourServices__card"
            style={{
              backgroundImage: `url("https://res.cloudinary.com/djfzcmvmr/image/upload/v1721187699/sri-sri/service_ent_3_sxpnbf.jpg")`,
            }}
          >
            <h4 className="ourServices__card--header">ENTERTAINMENT</h4>
            <p className="ourServices__card--title titleOne">
              – Celebrity Management
            </p>
            <p className="ourServices__card--title titleTwo">
              – Dance Choreography
            </p>
            <p className="ourServices__card--title titleThree">
              – All type of bands
            </p>
            <div className="ourServices__card--overlay"></div>
          </div>
          <div
            className="ourServices__card"
            style={{
              backgroundImage: `url("https://res.cloudinary.com/djfzcmvmr/image/upload/v1721187702/sri-sri/service_ven_4_lborlp.webp")`,
            }}
          >
            <h4 className="ourServices__card--header">DESTINATION & VENUES</h4>
            <p className="ourServices__card--title titleOne">– Logistics</p>
            <p className="ourServices__card--title titleTwo">– Management</p>
            <div className="ourServices__card--overlay"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurServices;
