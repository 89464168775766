import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CommonSectionHeader from "../../components/Shared/CommonSectionHeader";
import CommonTitle from "../../components/Shared/CommonTitle";

const OurClients = () => {
  const settings = {
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    speed: 2000,
    cssEase: "ease",
    dots: true,
  };

  const items = [
    "https://res.cloudinary.com/djfzcmvmr/image/upload/v1721190900/sri-sri/cli_1_oqqrqc.jpg",
    "https://res.cloudinary.com/djfzcmvmr/image/upload/v1721187820/sri-sri/cli_2_aa0qqk.jpg",
    "https://res.cloudinary.com/djfzcmvmr/image/upload/v1721187771/sri-sri/cli_3_dwltqe.jpg",
    "https://res.cloudinary.com/djfzcmvmr/image/upload/v1721190900/sri-sri/cli_4_ndctap.jpg",
    "https://res.cloudinary.com/djfzcmvmr/image/upload/v1721187760/sri-sri/cli_5_rhzrhy.jpg",
  ];

  return (
    <div className="ourClients-section">
      <CommonSectionHeader text="Our Clients" />
      <div className="ourClients-container">
        <Slider {...settings} className="ourClients__body">
          {items.map((img, index) => (
            <div key={index} className="ourClients__item">
              <img src={img} alt="" className="ourClients__item--img" />
              <div className="ourClients__item--overlay"></div>
            </div>
          ))}
        </Slider>
      </div>
      <div className="common-container">
        <div className="clients__section">
          <CommonTitle text="Amatra spa" />
          <CommonTitle text="Greenways" />
          <CommonTitle text="Zorroco" />
          <CommonTitle text="Atlas cycles" />
          <CommonTitle text="Rathi steel" />
          <CommonTitle text="Ntpc" />
          <CommonTitle text="Kolcorp Dubai" />
          <CommonTitle text="Lingayas" />
          <CommonTitle text="Apeejay Saket." />
          <CommonTitle text="Modern school." />
          <CommonTitle text="Swarnprastha school." />
          <CommonTitle text="Birla vidya niketan" />
          <CommonTitle text="Power grid." />
          <CommonTitle text="Golden exporters." />
          <CommonTitle text="Apeejay Saket." />
          <CommonTitle text="Mahagun builders." />
          <CommonTitle text="Swarnprastha school." />
          <CommonTitle text="Tayal motors." />
        </div>
        <div className="much_more">
          <CommonTitle text="And Much More." />
        </div>
      </div>
    </div>
  );
};

export default OurClients;
