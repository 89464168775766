import React from "react";
import { FaFacebookF, FaYoutube } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa6";
import { Link as ScrollLink } from "react-scroll";

const Footer = () => {
  return (
    <div className="footer">
      <div className="common-container">
        <div className="footer-wrapper">
          <div className="footer__logo">
            <img
              src="https://res.cloudinary.com/djfzcmvmr/image/upload/v1721189147/sri-sri/logo512_ovjjbs.png"
              className="footer__logo--img"
              alt=""
            />
          </div>
          <div className="footer__route">
            <ScrollLink
              to="services"
              spy={true}
              smooth={true}
              offset={0}
              duration={500}
            >
              <h4 className="footer__route--text">Services</h4>
            </ScrollLink>
          </div>
          <div className="footer__social">
            <FaFacebookF className="footer__social--icon" />
            <FaInstagram className="footer__social--icon" />
            <FaYoutube className="footer__social--icon" />
          </div>
        </div>
      </div>
      <div className="footer-overlay"></div>
    </div>
  );
};

export default Footer;
